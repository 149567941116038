<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: ["id_jadwal", "id_simulasi", "nip_asesi", "intaketype", "nama_simulasi"],
  components: {
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = [];
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "100px", label: "Terakhir Disimpan", name: "-" },
      { width: "150px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -ka tidak ada sorting kasih name dengan karakter -
    ];
    return {
      // data table
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      tableData: {
        per_page: 10,
        search: "",
        sort_field: "id_jawaban_history",
        sort_order: "desc",
        filter_status: "1", // untuk filtering data
        id_jadwal: this.id_jadwal,
        id_simulasi: this.id_simulasi,
        nip_asesi: this.nip_asesi,
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      show_modal_history_jawaban: false,
      history_jawaban_asesi: "",
      intaketype_data: this.intaketype,
      data_history: null,
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "monitoring/data-history-jawaban") {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {

          let response_data = response.data;
          let response_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_fix.data;
            self.configPagination(response_fix);
            self.currentTablePage = response_fix.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_fix.data.message,
            });
          }
        })
        .catch(() => {

          self.loadingTable = false;
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    showHistoryJawaban(row_jawaban) {
      this.data_history = row_jawaban;
      this.history_jawaban_asesi = row_jawaban.jawaban;
      this.show_modal_history_jawaban = !this.show_modal_history_jawaban;
    },
    restoreJawaban() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "monitoring/restore-jawaban-peserta";
      var config = {
        method: "post",
        url: url,
        data: {
          intake_type: this.intaketype_data,
          data_history: JSON.stringify(self.data_history),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Berhasil restore Jawaban.",
            });
            window.location.reload();
          } else {

            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {

          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors,
          });
        });
    },
  },
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">History Jawaban {{ this.nama_simulasi }}</h4>
      <div class="row mb-2">
        <div class="col-md-4">
          <table>
            <tr>
              <td>Show</td>
              <td>
                <select
                  class="form-control form-control-sm"
                  id="showpaginatetable"
                  style="width: 100%"
                  v-model="tableData.per_page"
                  @change="getDataTable()"
                >
                  <option
                    value="10"
                    selected
                  >10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                </select>
              </td>
              <td>Entries</td>
            </tr>
          </table>
        </div>
        <div class="col-md-5">&nbsp;</div>
        <div class="col-md-3">
          <input
            type="text"
            class="form-control form-control-sm"
            id="searchTable"
            placeholder="Cari Data ..."
            v-model="tableData.search"
            @input="getDataTable()"
          />
        </div>
      </div>
      <datatable
        :columns="columns"
        :sortKey="sortKey"
        :sortOrders="sortOrders"
        @sort="sortBy"
      >
        <tbody>
          <tr v-if="loadingTable">
            <td colspan="9"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
          </tr>
          <tr v-else-if="table_data == ''">
            <td
              class="text-center"
              colspan="3"
            >Data Tidak Tersedia</td>
          </tr>
          <tr
            v-else
            v-for="(row_data, key_data) in table_data"
            :key="key_data"
          >
            <td class="text-center">
              <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
              <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
            </td>
            <td>{{ row_data.created_at }}</td>
            <td>
              <b-button
                variant="primary"
                size="sm"
                @click="showHistoryJawaban(row_data)"
              > Lihat Jawaban </b-button>
            </td>
          </tr>
        </tbody>
      </datatable>
      <pagination
        :pagination="pagination"
        @to-page="toPage"
      ></pagination>
    </div>
  </div>
  <b-modal
    v-model="show_modal_history_jawaban"
    id="modal-lihat-jawaban"
    title="Lihat Jawaban"
    size="lg"
    hide-footer
  >
    <div v-html="this.history_jawaban_asesi"></div>
    <hr />
    <div class="card-footer">
      <div class="row">
        <div class="col-12">
          <div class="text-end">
            <b-button
              type="button"
              variant="info"
              @click="restoreJawaban()"
            > <i class="fa fa-redo-alt"></i> Restore Jawaban </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
