<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import FormDuplicate from "@/router/views/master/kuisioner/form-duplicate";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  page: {
    title: "Kuesioner",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
    FormDuplicate,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "150px", label: "No Pertanyaan", name: "nomor_pertanyaan" },
      { width: "auto", label: "Pertanyaan", name: "pertanyaan" },
      // { width: "100px", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      { width: "175px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Kuesioner >",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Kuesioner",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      idSimulasiConfig: this.$route.params.id,
      formTitle: "",
      dataForm: {
        nomor_pertanyaan: undefined,
        pertanyaan: undefined,
        simulasi_config_id: this.$route.params.id,
      },
      isLock: false,
      isEdit: false,
      isOpenModal: false,
      isOpenModalDuplicate: false,
      dataSimulasiConfig: {},

      // parsing data ke table data
      tableData: {
        per_page: 10,
        search: "",
        sort_field: "nomor_pertanyaan",
        sort_order: "asc",
        filter_status: "1", // untuk filtering data
        simulasi_config_id: this.$route.params.id,
        is_combo: 0,
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.getDataSimulasiConfig();
    self.getDataTable();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/kuisioner"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list;

            self.configPagination(response_data_fix.pagination);
            self.currentTablePage = response_data_fix.pagination.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(() => {

        });
      self.cekLockStatus();
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    getDataSimulasiConfig() {
      let self = this;
      let config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/simulasi-config/" +
          self.idSimulasiConfig,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config).then((response) => {
        let response_data = response.data;
        self.dataSimulasiConfig = response_data.data;
        self.title = "Kuesioner > " + self.dataSimulasiConfig.nama;
      });
    },
    isAdmin() {
      return localStorage.session_role_active_name == "Administrator";
    },
    cekLockStatus() {
      let self = this;
      let config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/kuisioner/lock/" +
          self.idSimulasiConfig +
          "/status",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config).then((response) => {
        let response_data = response.data;
        self.isLock = response_data.data;
      });
    },
    lockData() {
      let self = this;
      let title = "";
      let text = "";
      let type = "";
      if (self.isLock) {
        title = "Apakah Anda yakin akan membuka kunci data kuisioner ?";
        text = "Data yang terbuka akan bisa diedit";
        type = "unlock";
      } else {
        title = "Apakah Anda yakin akan mengunci data kuisioner ?";
        text = "Data yang dikunci tidak akan dapat di ubah";
        type = "lock";
      }
      Swal.fire({
        icon: "warning",
        title: title,
        text: text,
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, saya Yakin!",
      }).then((result) => {
        if (result.isConfirmed) {
          let config = {
            method: "put",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/kuisioner/lock/" +
              self.idSimulasiConfig +
              "/" +
              type,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
              access: "all-role",
              role_id: localStorage.session_role_active_id,
            },
          };
          axios(config).then(() => {

            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Data berhasil diperbarui.",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.getDataTable();
              }
            });
          });
        }
      });
    },
    addData() {
      let self = this;
      self.formTitle = "Tambah Pertanyaan";
      self.dataForm = {
        nomor_pertanyaan: undefined,
        pertanyaan: undefined,
        simulasi_config_id: this.$route.params.id,
      };
      self.isEdit = false;
      self.isOpenModal = true;
    },
    editData(dataRow) {
      let self = this;
      self.formTitle = "Ubah Pertanyaan";
      self.dataForm = {
        nomor_pertanyaan: dataRow.nomor_pertanyaan,
        pertanyaan: dataRow.pertanyaan,
        simulasi_config_id: this.$route.params.id,
        kuisioner_id: dataRow.kuesioner_id,
        isLockKuesioner: dataRow.isLockKuesioner,
        lockedKuisionerBy: dataRow.lockedKuisionerBy,
        lockedKuisionerAt: dataRow.lockedKuisionerAt,
        userLock: dataRow.userLock,
        is_active: dataRow.is_active,
      };
      self.isEdit = true;
      self.isOpenModal = true;
    },
    saveData() {
      let self = this;
      let url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/kuisioner";
      let method = "post";
      if (self.isEdit) {
        url = url + "/" + self.dataForm.kuisioner_id;
        method = "put";
      }
      let config = {
        method: method,
        url: url,
        data: self.dataForm,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then(() => {

          Swal.fire({
            icon: "success",
            title: "Data berhasil disimpan",
            timer: 1500,
            showConfirmButton: false,
          });
          (self.isOpenModal = false), self.getDataTable();
        })
        .catch((errors) => {

          let error = errors.response.data.data.error;
          let stringErr = "";
          error.forEach((e) => {
            stringErr += `${e}<br />`;
          });
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            html: stringErr,
          });
        });
    },
    changeStatusData(data) {
      let self = this;

      let text_alert = "";
      if (data.is_active == 0) {
        text_alert = "Apakah anda yakin ingin mengaktifkan data tersebut?";
      } else {
        text_alert = "Apakah anda yakin ingin menonaktifkan data tersebut?";
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/kuisioner/" +
              data.kuesioner_id +
              "/status",
            data: {},
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diperbarui.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/kuisioner/" +
              id,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    duplicateData() {
      let self = this;
      self.isOpenModalDuplicate = true;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="form-group col-md-2">
                    <label for="statusTable">Status</label>
                    <select
                      class="form-select"
                      id="statusTable"
                      v-model="tableData.filter_status"
                      @change="getDataTable()"
                    >
                      <option value="1">ENABLE</option>
                      <option value="0">DISABLE</option>
                    </select>
                  </div>
                  <div class="col-md-10 align-self-center">
                    <div class="text-end">
                      <button
                        class="btn btn-sm btn-info"
                        :disabled="isLock"
                        @click="duplicateData()"
                      >
                        <i class="bx bx-duplicate"></i> Duplikate Pertanyaan
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-sm btn-success"
                        :disabled="isLock"
                        @click="addData()"
                      >
                        <i class="fa fa-plus"></i> Tambah Data
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-sm btn-warning"
                        @click="lockData()"
                        v-if="
                          isAdmin() &&
                          !isLock &&
                          table_data != '' &&
                          tableData.filter_status == 1
                        "
                        :disabled="tableData.filter_status == 0"
                      >
                        <i class="fa fa-lock"></i> Kunci Data
                      </button>
                      <button
                        class="btn btn-sm btn-warning"
                        @click="lockData()"
                        v-if="
                          isAdmin() &&
                          isLock &&
                          table_data != '' &&
                          tableData.filter_status == 1
                        "
                        :disabled="tableData.filter_status == 0"
                      >
                        <i class="fa fa-lock-open"></i> Buka Kunci Data
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            style="width: 100%"
                            v-model="tableData.per_page"
                            @change="getDataTable()"
                          >
                            <option
                              value="10"
                              selected
                            >10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableData.search"
                      @input="getDataTable()"
                    />
                  </div>
                </div>
              </div>
              <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="6">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="table_data == ''">
                    <td
                      class="text-center"
                      colspan="6"
                    >Data Tidak Tersedia</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(row_data, key_data) in table_data"
                    :key="key_data"
                  >
                    <td class="text-center">
                      <div v-if="key_data + 1 == 10">
                        {{ currentTablePage }}0.
                      </div>
                      <div v-else>
                        {{
                          currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                        }}{{ key_data + 1 }}.
                      </div>
                    </td>
                    <td>
                      {{ row_data.nomor_pertanyaan }}
                    </td>
                    <td>
                      {{ row_data.pertanyaan }}
                    </td>
                    <td class="text-center">
                      <div class="btn-group">
                        <button
                          class="btn btn-info btn-sm"
                          @click="editData(row_data)"
                        >
                          <i class="fa fa-eye"></i> Detail
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          v-on:click="changeStatusData(row_data)"
                          :disabled="row_data.isLockKuesioner == 1"
                          v-if="row_data.is_active == 1"
                        >
                          <i class="fa fa-trash"></i> Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </datatable>
            </div>
            <pagination
              :pagination="pagination"
              @to-page="toPage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="isOpenModal"
      variant="danger"
      size="lg"
      :modal-footer="false"
      :title="formTitle"
    >
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            class="mb-3"
            label="Nomor Pertanyaan"
            label-for="formrow-nomor-pertanyaan"
          >
            <b-form-input
              id="formrow-nomor-pertanyaan"
              placeholder="Masukkan nomor urut pertanyaan"
              type="number"
              v-model="dataForm.nomor_pertanyaan"
              :readonly="dataForm.isLockKuesioner == 1 || dataForm.is_active == 0
                "
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            class="mb-3"
            label="Pertanyaan"
            label-for="formrow-pertanyaan"
          >
            <b-form-input
              id="formrow-pertanyaan"
              placeholder="Masukkan pertanyaan"
              type="text"
              v-model="dataForm.pertanyaan"
              :readonly="dataForm.isLockKuesioner == 1 || dataForm.is_active == 0
                "
            ></b-form-input>
          </b-form-group>
        </div>
        <div
          class="col-md-12"
          v-if="dataForm.isLockKuesioner == 1"
        >
          <span class="mb-3 text-danger">
            *Terakhir data dikunci oleh <b>{{ dataForm.userLock.name }}</b> pada
            <b>{{ dataForm.lockedKuisionerAt }}</b>.
          </span>
        </div>
      </div>
      <template #footer>
        <b-button @click="isOpenModal = false">
          <i class="fa fa-times"></i> Cancel
        </b-button>
        <b-button
          variant="success"
          @click="saveData()"
        >
          <i class="fa fa-save"></i> Save
        </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="isOpenModalDuplicate"
      size="md"
      title="Duplikat Pertanyaan Simulasi"
      title-class="font-18"
      hide-footer
    >
      <FormDuplicate
        :simulasiId="[idSimulasiConfig]"
        :isCombo="0"
        @closeModal="(isOpenModalDuplicate = false), getDataTable()"
      />
    </b-modal>
  </Layout>
</template>
