<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import TableHistoryJawaban from "./table-history-jawaban.vue";
import Swal from "sweetalert2";
import MateriDetailMonitoring from "./materi-detail-monitoring.vue";
import { DocumentEditor } from "@onlyoffice/document-editor-vue";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Materi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    TableHistoryJawaban,
    MateriDetailMonitoring,
    DocumentEditor,
  },
  data() {
    return {
      title_header: "",
      items_header: [
        {
          text: "Detail Monitoring PTL",
          active: true,
        },
      ],
      data_jadwal: [],
      data_simulasi: [],
      selected_id_simulasi: "",
      renderDetail: false,
      selected_nama_simulasi: "",
      data_jadwal_detail: {},
      tipe_jawaban: "",
      jawaban_asesi: "",
      created_by_jawaban: "",
      created_at_jawaban: "",
      updated_by_jawaban: "",
      file_jawaban: "",
      intake_type: this.$route.params.intake_type,
      renderCounter: 0,
      tabIndex: 0,
      items: [
        { id: 1, title: "Item 1", content: "Content of Item 1" },
        { id: 2, title: "Item 2", content: "Content of Item 2" },
        { id: 3, title: "Item 3", content: "Content of Item 3" },
      ],
      selectedItem: null,
      url_onlyoffice: process.env.VUE_APP_ONLYOFFICE_URL,
      config: {
        type: "desktop",
        width: "100%",
        height: "100%",
        documentType: "slide",
        document: {
          key: `${new Date().toISOString()}-${this.$route.params.id_jadwal}-${this.$route.params.id_jadwal_detail}`,
          title: `presentasi_${this.$route.params.id}_${this.$route.params.id_jadwal_detail}.pptx`,
          url: `${process.env.VUE_APP_BACKEND_URL_VERSION}callback-onlyoffice/${this.$route.params.id}/${this.$route.params.id_jadwal_detail}`,
          fileType: "pptx",
          permissions: {
            edit: true,
            download: false,
            print: false,
            copy: false,
          },
        },
        editorConfig: {
          mode: "view",
          callbackUrl: `${process.env.VUE_APP_BACKEND_URL_VERSION}callback-onlyoffice/${this.$route.params.id}/${this.$route.params.id_jadwal_detail}`,
          customization: {
            autosave: true,
            chat: false,
            comments: false,
            forcesave: true,
            hideRightMenu: true,
            plugins: false,
            zoom: -1,
            anonymous: {
              request: false,
            },
          },
        },
      },
    };
  },
  computed: {
    formattedJawaban() {
      return this.jawaban_asesi.replace('<table', '<table class="table table-bordered" ');
    }
  },

  mounted() {
    this.getDataJadwal();
    this.getDataJadwalDetail();
    this.getDataJawabanAsesi();
  },
  methods: {
    toggleItem(item) {
      if (this.selectedItem === item) {
        this.selectedItem = null;
      } else {
        this.selectedItem = item;
      }
    },
    // Method dari masing masing fungsi di dalam file ini
    getDataJadwal() {
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `monitoring/data-jadwal/${this.$route.params.id}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var responseData = response.data;
          this.data_jadwal = responseData.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    getDataJadwalDetail() {
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `monitoring/data-jadwal-detail/${this.$route.params.id}/${this.$route.params.id_simulasi}/${this.$route.params.nip_asesi}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var responseData = response.data;
          this.data_jadwal_detail = responseData.data.data;
          this.tipe_jawaban = this.data_jadwal_detail.simulasi_config.tipe_jawaban;
          this.data_jadwal_detail.nama_simulasi = this.data_jadwal_detail.simulasi_config.nama;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    getDataJawabanAsesi() {
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `monitoring/data-jawaban-peserta/${this.$route.params.id}/${this.$route.params.id_simulasi}/${this.$route.params.nip_asesi}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var responseData = response.data;
          let listJawaban = responseData.data.data;
          if (listJawaban.length > 0) {
            listJawaban.forEach((element) => {
              if (this.intake_type == "intaketype8" || this.intake_type == "intaketype7") {
                if (element.catatan_lgd == null) {
                  this.jawaban_asesi = null;
                } else {
                  this.jawaban_asesi += `${element.catatan_lgd} <br>`;
                  this.created_at_jawaban = element.updated_at;
                  this.created_by_jawaban = element.created_by;
                  this.updated_by_jawaban = element.updated_by;

                  // alert("here");
                }
              } else {
                if (element.jawaban == null) {
                  this.jawaban_asesi = null;
                } else {
                  this.jawaban_asesi += `${element.jawaban} <br>`;
                  this.created_at_jawaban = element.updated_at;
                  this.created_by_jawaban = element.created_by;
                  this.updated_by_jawaban = element.updated_by;
                }
              }
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    onChangeFileUpload() {
      this.file_jawaban = this.$refs.data_jawaban.files[0];
    },
    uploadDataJawaban() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      let formData = new FormData();
      formData.append("data_jawaban", this.file_jawaban);
      formData.append("id_jadwal_detail", this.data_jadwal_detail.id);
      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "monitoring/upload-data-jawaban",
        data: formData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_store_data)
        .then(function (response) {
          var responseData = response.data;
          if (responseData.meta.status == "success") {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil upload data jawaban.",
              timer: 1000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.jawaban_asesi = responseData.data.data;
                self.renderCounter += 1; // re render table history jawaban
                self.$refs.data_jawaban.value = null;
                self.file_jawaban = null;
                self.tabIndex = 0;
                Swal.close();
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "oops",
              text: "Gagal upload data jawaban.",
              timer: 1000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
              }
            });
          }
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    downloadJawaban() {
      let url_download_jawaban = `${process.env.VUE_APP_BACKEND_URL}/fitur/download-jawaban-peserta/${this.$route.params.id}/${this.$route.params.id_simulasi}/${this.$route.params.nip_asesi}/${this.data_jadwal_detail.nama_simulasi}/${this.data_jadwal_detail.pin}`;
      window.open(url_download_jawaban, "_blank");
    },
    downloadJawabanWord() {
      let url_download_jawaban = `${process.env.VUE_APP_BACKEND_URL}/fitur/download-jawaban-peserta/${this.$route.params.id}/${this.$route.params.id_simulasi}/${this.$route.params.nip_asesi}/${this.data_jadwal_detail.nama_simulasi}/${this.data_jadwal_detail.pin}/word`;
      window.open(url_download_jawaban, "_blank");
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader
      :title="title_header"
      :items="items_header"
    />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <h4>
                Penilaian {{ data_jadwal_detail.nama_simulasi }} untuk Asesi {{ data_jadwal_detail.nama_asesi }} dengan
                PIN
                {{ data_jadwal_detail.pin }}
              </h4>
              <hr />
              <div class="row">
                <div class="col-6">
                  <table style="width: 100%; border: 1px">
                    <tr>
                      <td>ID Jadwal</td>
                      <td>:</td>
                      <td>{{ data_jadwal?.idjadwal }}</td>
                    </tr>
                    <tr>
                      <td>Tanggal</td>
                      <td>:</td>
                      <td>
                        {{ data_jadwal?.j_tanggal_mulai }} sd
                        {{ data_jadwal?.j_tanggal_sampai }}
                      </td>
                    </tr>
                    <tr>
                      <td>Jenis Asesmen</td>
                      <td>:</td>
                      <td>{{ data_jadwal?.j_txt_dirkom }}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-6">
                  <table style="width: 100%; border: 1px">
                    <tr>
                      <td>Materi</td>
                      <td>:</td>
                      <td>{{ data_jadwal?.j_materi }}</td>
                    </tr>
                    <tr>
                      <td>PTL</td>
                      <td>:</td>
                      <td>{{ data_jadwal?.j_txt_ptl }}</td>
                    </tr>
                    <tr>
                      <td>Petugas</td>
                      <td>:</td>
                      <td>
                        <span
                          v-for="(petugas, key) in data_jadwal?.jadwal_petugas_new"
                          :key="key"
                        >
                          {{ petugas.jp_txt_petugas }} {{ " " }}{{ `(${petugas.jp_txt_tugas})` }}
                          <span v-if="!data_jadwal?.jadwal_petugas_new.length == key + 1">
                            {{ data_jadwal?.jadwal_petugas_new.length }}
                          </span>
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <b-tabs
            v-model="this.tabIndex"
            content-class="mt-3"
            fill
          >
            <b-tab
              :key="jawaban - asesi"
              v-if="this.tipe_jawaban == 'Rich Text' || this.tipe_jawaban == 'Rich Text - Discussion' || this.tipe_jawaban == 'Rich Text - Interaction'"
              title="Jawaban Asesi"
            >
              <div>
                <b-button-toolbar
                  key-nav
                  aria-label="Toolbar with button groups"
                >
                  <b-button-group class="mx-1">
                    <b-button
                      size="sm"
                      variant="primary"
                      type="button"
                      @click="downloadJawaban"
                    >
                      <i class="fa fa-arrow-down"></i>
                      Download Jawaban (PDF)
                    </b-button>
                  </b-button-group>
                  <b-button-group class="mx-1">
                    <b-button
                      size="sm"
                      variant="primary"
                      type="button"
                      @click="downloadJawabanWord"
                    >
                      <i class="fa fa-arrow-down"></i>
                      Download Jawaban (WORD)
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
              <hr />
              <div v-if="this.jawaban_asesi == null">Belum ada jawaban</div>
              <div v-else>
                <div v-html="formattedJawaban">
                </div>
                <table>
                  <tr>
                    <td><b>Tanggal Upload &nbsp;</b></td>
                    <td>: &nbsp;</td>
                    <td>{{ this.created_at_jawaban != null ? this.created_at_jawaban : "-" }}</td>
                  </tr>
                  <tr>
                    <td><b>Diupload Oleh</b></td>
                    <td>:</td>
                    <td v-if="this.created_by_jawaban != null || this.updated_by_jawaban != null">{{
                      this.created_by_jawaban != null ? this.created_by_jawaban : this.updated_by_jawaban }}</td>
                    <td v-else>-</td>
                  </tr>
                </table>
              </div>
              <p v-if="this.jawaban_asesi == ''">Belum ada jawaban.</p>
            </b-tab>
            <b-tab
              :key="persiapan - asesi"
              v-if="tipe_jawaban == 'PPT (Only Office)' || tipe_jawaban == 'PPT (Only Office) - Interaction'"
              title="Persiapan Asesi"
            >
              <!-- <div>
                <b-button-toolbar key-nav aria-label="Toolbar with button groups">
                  <b-button-group class="mx-1">
                    <a class="btn btn-xs btn-primary" :href="this.config.document.url">
                      <i class="fa fa-arrow-down"></i>
                      Download PPT
                    </a>
                  </b-button-group>
                </b-button-toolbar>
              </div> -->
              <!-- <hr> -->
              <div style="height: 1000px">
                <a
                  class="btn btn-xs btn-primary"
                  :href="this.config.document.url"
                >
                  <i class="fa fa-arrow-down"></i>
                  Download PPT
                </a>
                <hr />
                <DocumentEditor
                  id="docEditor"
                  :document-server-url="url_onlyoffice"
                  :config="config"
                  :events_onDocumentReady="onDocumentReady"
                />
              </div>
            </b-tab>
            <b-tab
              :key="materi"
              title="Materi"
            >
              <MateriDetailMonitoring />
            </b-tab>
            <b-tab
              :key="upload - data - jawaban"
              v-if="this.tipe_jawaban == 'Rich Text' && this.$route.params.posisi == 'PTL'"
              title="Upload Data Jawaban"
            >
              <b-form
                class="p-2"
                @submit.prevent="uploadDataJawaban"
              >
                <div class="form-group">
                  <label> Upload File </label>
                  <input
                    type="file"
                    name="data_jawaban"
                    ref="data_jawaban"
                    class="form-control"
                    accept=".doc,.docx"
                    v-on:change="onChangeFileUpload()"
                  />
                  <input
                    type="hidden"
                    name="id_jadwal_detail"
                    v-model="this.data_jadwal_detail.id"
                  />
                </div>
                <div class="form-group">
                  <label>&nbsp;</label>
                  <br />
                  <button
                    type="submit"
                    class="btn btn-primary pull-right"
                  >Upload Data Jawaban</button>
                </div>
              </b-form>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <TableHistoryJawaban
        v-if="this.tipe_jawaban == 'Rich Text' && this.$route.params.posisi != 'Asesor'"
        :id_jadwal="this.$route.params.id"
        :id_simulasi="this.$route.params.id_simulasi"
        :nip_asesi="this.$route.params.nip_asesi"
        :intaketype="this.$route.params.intake_type"
        :nama_simulasi="this.data_jadwal_detail.nama_simulasi"
        :key="this.renderCounter"
      />
    </div>
  </Layout>
</template>