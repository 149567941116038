<script>
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
// import Swal from "sweetalert2";
// import Swal from "sweetalert2";
export default {
  props: ["existing_simulasi_config_id", "existing_no_urut", "materi_id"],
  components: {
    Multiselect,
  },
  data() {
    return {
      // variable Page
      option_materi: [],
      selected_materi: [],

      option_simulasi: [],
      selected_simulasi: [],

      validateSimulasi: false,
    };
  },
  mounted() {
    this.getMateri();
  },
  methods: {
    getMateri() {
      let self = this;

      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/materi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then(function (response) {
          self.option_materi = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },

    onSelectMateri() {
      this.option_simulasi = [];
      this.option_simulasi = this.selected_materi.simulasi;

      if (this.option_simulasi.length > 0) {
        this.validateSimulasi = true;
      } else {
        this.validateSimulasi = false;
      }
    },

    StoreData() {
      let materi_id_target = this.selected_materi.mm_id;
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/materi/copy-materi/" + this.$route.params.id,
        data: {
          materi_id_target: materi_id_target
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then(function () {

          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master simulasi  segera",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              window.location.reload();
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>
<template>
  <b-form
    class="p-2"
    @submit.prevent="StoreData"
  >
    <div class="row">
      <div class="col-md-12">
        <b-form-group
          class="mb-3"
          label="Materi"
          label-for="formrow-nama-role-input"
        >
          <multiselect
            v-model="selected_materi"
            :options="option_materi"
            label="mm_nama"
            :object="true"
            value-prop="mm_id"
            placeholder="Pilih Materi"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            @update:modelValue="onSelectMateri"
            required
          ></multiselect>
        </b-form-group>
      </div>
    </div>

    <div
      class="row"
      v-if="!validateSimulasi"
    >
      <div class="col-md-12">
        <span class="danger badge badge-pill badge-soft-danger">Materi tidak memiliki simulasi</span>
      </div>
    </div>

    <hr />
    <div class="row mt-2">
      <div class="col-12 text-end">
        <button
          type="submit"
          class="btn btn-primary btn-md"
          :disabled="!validateSimulasi"
        >
          <i class="fa fa-edit"></i> Simpan
        </button>
      </div>
    </div>
  </b-form>
</template>
