<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  emits: ["closeModal"],
  components: {},
  props: [
    "tipeMateri",
    "idJadwal",
    "simulasiId",
    "idMateri",
    "dataSettingJadwal",
  ],
  data() {
    return {
      listMateri: [],
      pilihanMateri: "",
      dataMateriFile: [],

      namaMateri: "-",
      listMateriFile: [],
    };
  },
  mounted() {
    this.setPostData();
    this.getListMateri(); // get listing materi berdasarkan simulasi
    // this.getMateriSimulai(); // get materi simulasi
    // this.getFileMateri(); // get dropdown file materi
  },
  methods: {
    setPostData() {
      let self = this;

      let config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "dashboard/data-materi-simulasi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        data: {
          simulasiId: this.$props.simulasiId,
          idJadwal: this.$props.idJadwal,
          postData: JSON.stringify(this.$props.dataSettingJadwal),
        },
      };

      axios(config).then((response) => {
        let response_data = response.data;
        self.dataMateriFile = response_data.data.simulasi_materi;
        if (response_data.data.id_materi != "") {
          self.pilihanMateri = response_data.data.id_materi;
        } else {
          self.pilihanMateri = self.$props.idMateri;
        }
        self.getFileMateri();
      });

      // self.$props.dataSettingJadwal.forEach((value) => {
      //   let nip = "-";
      //   let nama = "-";
      //   if (value.asesi_master) {
      //     nip = value.asesi_master.u_nip;
      //     nama = value.asesi_master.u_nama;
      //   }

      //   self.dataMateriFile.push({
      //     id_materi_simulasi: null,
      //     jadwal_detail_id: value.jadwal_detail_id,
      //     simulasi_id_jadwal: this.$props.simulasiId,
      //     pin: value.pin,
      //     nip: nip,
      //     nama: nama,
      //     materi_file_id: "",
      //   });
      // });
    },
    getListMateri() {
      let config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "dashboard/list-materi-simulasi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          simulasi_id: this.$props.simulasiId,
        },
      };
      axios(config).then((response) => {
        let self = this;
        let response_data = response.data;
        self.listMateri = response_data.data;
      });
    },
    getFileMateri() {
      let self = this;
      let config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "dashboard/file-materi-simulasi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
        params: {
          simulasi_id: self.$props.simulasiId,
          materi_id: self.pilihanMateri,
        },
      };
      axios(config).then((response) => {
        let response_data = response.data;
        self.namaMateri = response_data.data.nama_materi;
        self.listMateriFile = response_data.data.materi_file;
      });
    },
    checkDataMateriFile() {
      console.log(true)
    },
    saveMateri() {
      let self = this;
      Swal.fire({
        title: '<i class="bx bx-loader-alt bx-spin bx-lg"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var configStore = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "dashboard/store-materi-simulasi",
        data: {
          idJadwal: self.$props.idJadwal,
          simulasiId: self.$props.simulasiId,
          pilihanMateri: self.pilihanMateri,
          tipeMateri: self.$props.tipeMateri,
          postData: JSON.stringify(self.dataMateriFile),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(configStore)
        .then(() => {

          Swal.fire({
            icon: "success",
            title: "Data berhasil disimpan",
            timer: 1500,
            showConfirmButton: false,
          });
          this.$emit("closeModal");
        })
        .catch((errors) => {

          let error = errors.response.data.data.error;
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: error,
          });
        });
    },
  },
};
</script>
<template>
  <b-form
    class="p-2"
    @submit.prevent="saveMateri"
  >
    <div class="row">
      <div class="col-12">
        <!-- 
          <b-form-group label="Materi" label-for="formrow-materi">
            <select
              class="form-control"
              v-model="pilihanMateri"
              @change="getFileMateri()"
            >
              <option value="">-- Pilih Materi --</option>
              <option
                v-for="(value, key) in listMateri"
                :key="key"
                :value="value.mm_id"
              >
                {{ value.mm_nama }}
              </option>
            </select>
          </b-form-group> 
        -->
        <b-form-group
          label="Materi"
          label-for="formrow-materi"
        >
          <input
            type="text"
            readonly="true"
            class="form-control"
            v-model="namaMateri"
          />
        </b-form-group>
        <hr />
      </div>
      <div class="col-12">
        <table class="table mb-0 table-bordered table-condensed table-hover">
          <thead class="bg-dark text-center text-white">
            <tr>
              <th width="50px">No</th>
              <th>PIN</th>
              <th>NIP</th>
              <th>Nama</th>
              <th>Materi</th>
              <th>Pilih File</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(val, key) in dataMateriFile"
              :key="key"
            >
              <td>{{ key + 1 }}</td>
              <td>{{ val.pin }}</td>
              <td>{{ val.nip }}</td>
              <td>{{ val.nama }}</td>
              <td>{{ namaMateri }}</td>
              <td>
                <div v-if="tipeMateri == 'Ambil File dan Pilih'">
                  <select
                    class="form-control form-control-sm"
                    v-model="val.materi_file_id"
                    @change="checkDataMateriFile()"
                    required="true"
                  >
                    <option value="">-- Pilih File --</option>
                    <option
                      v-for="(val_list_file, key_list_file) in listMateriFile"
                      :key="key_list_file"
                      :value="val_list_file.materi_file_id"
                    >
                      {{ val_list_file.simulasi_file.tipe == 'PDF' ? val_list_file.simulasi_file.nama_file + " - " +
                        val_list_file.simulasi_file.tipe : val_list_file.simulasi_file.judul + " - " +
                        val_list_file.simulasi_file.tipe }}
                    </option>
                  </select>
                </div>
                <div v-else-if="tipeMateri == 'Ambil File & Acak'">
                  <input
                    type="text"
                    readonly="true"
                    class="form-control"
                    :value="val.materi_file_nama"
                    placeholder="File Tidak Tersedia."
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-8 mt-2">
        <div v-if="tipeMateri == 'Ambil File dan Pilih'">
          <p style="color: red">
            *Catatan : mohon pilih materi dan pilihkan file untuk peserta asesi.
          </p>
        </div>
        <div v-else-if="tipeMateri == 'Ambil File & Acak'">
          <p style="color: red">
            *Catatan : jika file per peserta asesi masih kosong, mohon langsung
            klik tombol simpan dan sistem akan mengacak soal untuk setiap
            peserta.
          </p>
        </div>
      </div>
      <div class="col-4 mt-2">
        <div class="float-end">
          <button
            type="submit"
            class="btn btn-primary btn-sm"
          >
            <i class="bx bx-save"></i> Simpan
          </button>
        </div>
      </div>
    </div>
  </b-form>
</template>
