import { createWebHistory, createRouter } from "vue-router";

import routes from "./routes";
import axios from "axios";
import Swal from "sweetalert2";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'hash',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  if (authRequired && routeTo.path != "/login") {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      return next("/login");
    } else {
      try {
        let preloader = document.getElementById("preloader");
        if (preloader) {
          document.getElementById("preloader").style.display = "block";
          document.getElementById("status").style.display = "block";
        }

        // Make a request to your Laravel backend to check the token
        const response = await axios
          .post(
            process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-token",
            {},
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then((response) => response)
          .catch(() => {
            return false;
          });
        if (response === false) {
          if (preloader) {
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";
          }

          localStorage.removeItem("accessToken");
          next("login");
          let timerInterval;
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: "Silahkan login kembali",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        }
        if (response.data.data) {
          next();
          if (preloader) {
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";
          }
        } else {
          if (preloader) {
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";

            let timerInterval;
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: "Silahkan login kembali",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            });
          }
          localStorage.removeItem("accessToken");
          next("/login");
        }
      } catch (error) {
        localStorage.removeItem("accessToken");
        next("/login");
      }
    }
  } else {
    next();
  }
});

export default router;
