<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  emits: ["hideForm"],
  page: {
    title: "Form Tambah Batch",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    VueDatePicker,
  },
  props: ["idJadwal", "level", "tanggalMulai", "tanggalSelesai"],
  data() {
    return {
      title: "Form Tambah Batch",
      axiosCatchError: null,
      items: [],
      listSimulasi: [],
      tanggal_awal_jadwal: new Date(this.$props.tanggalMulai),
      tanggal_akhir_jadwal: new Date(this.$props.tanggalSelesai),
      validasi_tanggal_mulai: false,
      validasi_tanggal_selesai: false,
      formData: {
        idJadwal: this.idJadwal,
        nama_batch: "",
        simulasi_id: "",
        tanggal_awal: new Date(this.$props.tanggalMulai),
        tanggal_akhir: new Date(this.$props.tanggalSelesai),
      },
    };
  },
  mounted() {
    this.getListSimulasi();
  },
  methods: {
    getListSimulasi() {
      let self = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/simulasi-jadwal",
        params: {
          idJadwal: self.$props.idJadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config).then((response) => {
        let response_data = response.data;
        let response_fix = response_data.data;
        self.listSimulasi = response_fix.referensi;
      });
    },
    StoreData() {
      let self = this;
      let tanggal_awalnya = moment(self.tanggal_awal_jadwal).format("YYYY-MM-DD");
      let tanggal_akhirnya = moment(self.tanggal_akhir_jadwal).format("YYYY-MM-DD");

      Swal.fire({
        title: '<i class="bx bx-loader-alt bx-spin bx-lg"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var configStore = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "transaction/batch",
        data: {
          id_jadwal: self.formData.idJadwal,
          nama_batch: self.formData.nama_batch,
          simulasi: self.formData.simulasi_id,
          tanggal_waktu_mulai: moment(self.formData.tanggal_awal).format("YYYY-MM-DD HH:mm:ss"),
          tanggal_waktu_selesai: moment(self.formData.tanggal_akhir).format("YYYY-MM-DD HH:mm:ss"),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      if ((self.validasi_tanggal_mulai == false) && (self.validasi_tanggal_selesai == false)) {
        axios(configStore).then(() => {
          Swal.fire({
            icon: "success",
            title: "Data berhasil disimpan",
            timer: 1500,
            showConfirmButton: false,
          });
          self.$emit("hideForm");
        }).catch((errors) => {
          let error = errors.response.data.data.error;
          let stringErr = "";
          error.forEach((e) => {
            stringErr += `${e}<br />`;
          });
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            html: stringErr,
          });
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Tanggal Tidak Valid",
          text: "Tanggal Mulai dan Tanggal Selesai harus diantara Tanggal " + tanggal_awalnya + " s.d. " + tanggal_akhirnya,
        });
      }
    },
    validateTanggalMulai() {
      let self = this;
      let tanggal_awalnya = moment(self.tanggal_awal_jadwal).format("YYYY-MM-DD");
      let tanggal_akhirnya = moment(self.tanggal_akhir_jadwal).format("YYYY-MM-DD");
      let tanggal_mulai_validasi = moment(self.tanggal_awal_jadwal).format("YYYY-MM-DD");
      let tanggal_akhir_validasi = moment(self.tanggal_akhir_jadwal).format("YYYY-MM-DD");
      let tanggal_mulai_form = moment(self.formData.tanggal_awal).format("YYYY-MM-DD");

      if (tanggal_mulai_form < tanggal_mulai_validasi) {
        self.validasi_tanggal_mulai = true;
        Swal.fire({
          icon: "error",
          title: "Tanggal Tidak Valid",
          text: "Tanggal Mulai dan Tanggal Selesai harus diantara Tanggal " + tanggal_awalnya + " s.d. " + tanggal_akhirnya,
        });
      } else {
        if (tanggal_mulai_form > tanggal_akhir_validasi) {
          self.validasi_tanggal_mulai = true;
          Swal.fire({
            icon: "error",
            title: "Tanggal Tidak Valid",
            text: "Tanggal Mulai dan Tanggal Selesai harus diantara Tanggal " + tanggal_awalnya + " s.d. " + tanggal_akhirnya,
          });
        } else {
          self.validasi_tanggal_mulai = false;
        }
      }
    },
    validateTanggalAkhir() {
      let self = this;
      let tanggal_awalnya = moment(self.tanggal_awal_jadwal).format("YYYY-MM-DD");
      let tanggal_akhirnya = moment(self.tanggal_akhir_jadwal).format("YYYY-MM-DD");
      let tanggal_mulai_validasi = moment(self.tanggal_awal_jadwal).format("YYYY-MM-DD");
      let tanggal_akhir_validasi = moment(self.tanggal_akhir_jadwal).format("YYYY-MM-DD");
      let tanggal_akhir_form = moment(self.formData.tanggal_akhir).format("YYYY-MM-DD");
      if (tanggal_akhir_form < tanggal_mulai_validasi) {
        self.validasi_tanggal_selesai = true;
        Swal.fire({
          icon: "error",
          title: "Tanggal Tidak Valid",
          text: "Tanggal Mulai dan Tanggal Selesai harus diantara Tanggal " + tanggal_awalnya + " s.d. " + tanggal_akhirnya,
        });
      } else {
        if (tanggal_akhir_form > tanggal_akhir_validasi) {
          self.validasi_tanggal_selesai = true;
          Swal.fire({
            icon: "error",
            title: "Tanggal Tidak Valid",
            text: "Tanggal Mulai dan Tanggal Selesai harus diantara Tanggal " + tanggal_awalnya + " s.d. " + tanggal_akhirnya,
          });
        } else {
          self.validasi_tanggal_selesai = false
        }
      }
    }
  },
};
</script>
<template>
  <PageHeader
    :title="title"
    :items="items"
  />
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <b-form
            class="p-2"
            @submit.prevent="StoreData"
          >
            <!-- <div class="card-title">{{ "Form Batch" }}</div> -->
            <div class="row">
              <b-form-group
                class="mb-3"
                label="Nama Batch"
                label-for="formrow-nama"
              >
                <b-form-input
                  id="formrow-nama"
                  placeholder="Masukkan Nama Batch"
                  type="text"
                  v-model="formData.nama_batch"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="mb-3"
                label="Simulasi"
                label-for="formrow-simulasi"
              >
                <select
                  id="formrow-simulasi"
                  class="form-control form-control-sm"
                  v-model="formData.simulasi_id"
                >
                  <option value="">-- Pilih Simulasi --</option>
                  <option
                    v-for="(val, key) in listSimulasi"
                    :key="key"
                    :value="val.simulasi_id"
                  >
                    {{ val.simulasi_nama }}
                  </option>
                </select>
              </b-form-group>
              <b-form-group
                class="mb-3"
                label="Tanggal Waktu Mulai"
                label-for="formrow-tanggal_mulai"
              >
                <VueDatePicker
                  v-model="formData.tanggal_awal"
                  :teleport="true"
                  :format="'dd/MM/yyyy HH:mm'"
                  position="left"
                  auto-apply
                  @update:model-value="validateTanggalMulai"
                ></VueDatePicker>
              </b-form-group>
              <b-form-group
                class="mb-3"
                label="Tanggal Waktu Selesai"
                label-for="formrow-tanggal_akhir"
              >
                <VueDatePicker
                  v-model="formData.tanggal_akhir"
                  :teleport="true"
                  :format="'dd/MM/yyyy HH:mm'"
                  position="left"
                  auto-apply
                  @update:model-value="validateTanggalAkhir"
                ></VueDatePicker>
              </b-form-group>
            </div>
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                @click="$emit('hideForm')"
              >
                <i class="bx bx-exit"></i> Kembali
              </button>
              &nbsp;
              <button
                type="submit"
                class="btn btn-primary btn-sm"
              >
                <i class="bx bx-save"></i> Simpan
              </button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
