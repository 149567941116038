<script>
// import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  page: {
    title: "Pohon",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_pohon_profesi" },
      { width: "auto", label: "Nama Pohon Profesi", name: "nama_pohon_profesi" },
      { width: "150px", label: "Created At", name: "created_at" },
      { width: "150px", label: "Updated At", name: "updated_at" },
      { width: "100px", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      { width: "175px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Pohon Profesi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Pohon Profesi",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_pohon_profesi", // sort key awal
      sortOrders: sortOrders,

      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        filter_status: "ENABLE", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.getDataTable();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/pohon-profesi"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            if (self.tableData.draw == response_data_fix.draw) {
              self.table_data = response_data_fix.list_data.data;
              self.configPagination(response_data_fix.list_data);
            }
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(() => {

        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    changeStatusData(status, id) {
      let self = this;

      let text_alert = "";
      if (status == "ENABLE") {
        text_alert = "Apakah anda yakin ingin mengaktifkan data tersebut?";
      } else {
        text_alert = "Apakah anda yakin ingin menonaktifkan data tersebut?";
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/pohon-profesi",
            data: {
              id: id,
              status: status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diperbarui.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/pohon-profesi",
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>

<template>
  <PageHeader
    :title="title"
    :items="items"
  />
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="card-title">Semua Data Dahan Profesi</div>
            </div>
            <div class="col-md-6">
              <div class="text-end">
                <router-link
                  :to="{ name: 'add-role' }"
                  class="btn btn-sm btn-success"
                ><i class="fa fa-plus"></i> Tambah</router-link>
              </div>
            </div>
            <div class="col-md-12">
              <hr />
              <div class="row">
                <div class="form-group col-md-2">
                  <label for="statusTable">Status</label>
                  <select
                    class="form-select"
                    id="statusTable"
                    v-model="tableData.filter_status"
                    @change="getDataTable()"
                  >
                    <option value="ENABLE">ENABLE</option>
                    <option value="DISABLE">DISABLE</option>
                  </select>
                </div>
              </div>
              <hr />
            </div>
            <div class="col-md-12 mb-2">
              <div class="row">
                <div class="col-md-4">
                  <table>
                    <tr>
                      <td>Show</td>
                      <td>
                        <select
                          class="form-control form-control-sm"
                          id="showpaginatetable"
                          style="width: 100%"
                          v-model="tableData.length"
                          @change="getDataTable()"
                        >
                          <option
                            value="10"
                            selected
                          >10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                        </select>
                      </td>
                      <td>Entries</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-5">&nbsp;</div>
                <div class="col-md-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="searchTable"
                    placeholder="Cari Data ..."
                    v-model="tableData.search"
                    @input="getDataTable()"
                  />
                </div>
              </div>
            </div>
            <datatable
              :columns="columns"
              :sortKey="sortKey"
              :sortOrders="sortOrders"
              @sort="sortBy"
            >
              <tbody>
                <tr v-if="loadingTable">
                  <td colspan="6">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </td>
                </tr>
                <tr v-else-if="table_data == ''">
                  <td
                    class="text-center"
                    colspan="6"
                  >Data Tidak Tersedia</td>
                </tr>
                <tr
                  v-else
                  v-for="(row_data, key_data) in table_data"
                  :key="key_data"
                >
                  <td class="text-center">
                    {{ key_data + 1 }}
                  </td>
                  <td>
                    {{ row_data.nama_pohon_profesi }}
                  </td>
                  <td class="text-center">
                    {{
                      row_data.created_at
                        ? fullDateTimeFormat(row_data.created_at)
                        : "-"
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      row_data.updated_at
                        ? fullDateTimeFormat(row_data.updated_at)
                        : "-"
                    }}
                  </td>
                  <td class="text-center">
                    <div v-if="row_data.status == 'ENABLE'">
                      <div class="badge badge-pill badge-soft-success font-size-12">
                        ENABLE
                      </div>
                    </div>
                    <div v-else>
                      <div class="badge badge-pill badge-soft-danger font-size-12">
                        DISABLE
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div v-if="row_data.status == 'ENABLE'">
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          v-on:click="
                            changeStatusData('DISABLE', row_data.id_pohon_profesi)
                            "
                        >
                          <i class="fa fa-times"></i> Disable
                        </button>
                        <router-link
                          :to="{
                            name: 'edit-role',
                            params: { id: row_data.id_pohon_profesi },
                          }"
                          class="btn btn-info btn-sm"
                        ><i class="fa fa-edit"></i> Edit</router-link>
                      </div>
                    </div>
                    <div v-else>
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          v-on:click="
                            changeStatusData('ENABLE', row_data.id_pohon_profesi)
                            "
                        >
                          <i class="fas fa-undo"></i> Restore
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          v-on:click="deleteData(row_data.id_pohon_profesi)"
                        >
                          <i class="fa fa-trash"></i> Hapus
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </datatable>
          </div>
          <pagination
            :pagination="pagination"
            @prev="getDataTable(pagination.prevPageUrl)"
            @next="getDataTable(pagination.nextPageUrl)"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>
