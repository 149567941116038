<script>
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  emits: ["closeModal"],
  components: {
    Multiselect,
  },
  props: ["simulasiId", "isCombo"],
  data() {
    return {
      simulasi_id: this.$props.simulasiId,
      is_combo: this.$props.isCombo,
      listSimulasi: [],
      dataForm: {
        simulasiId: null,
      },
    };
  },
  mounted() {
    this.getSimulasi();
  },
  methods: {
    getSimulasi(keyword) {
      let self = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/simulasi-config-has-kuisioner",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
        params: {
          keyword,
          filter_simulasi_config_exist: this.$props.simulasiId,
          is_combo: self.is_combo,
        },
      };
      axios(config).then((response) => {
        let response_data = response.data;
        let response_fix = response_data.data;
        self.listSimulasi = response_fix.referensi;
      });
    },
    searchSimulasiConfig(data) {
      this.getSimulasi(data);
    },
    saveDuplicate() {
      let self = this;
      let config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/kuisioner/duplicate/" + this.$props.simulasiId,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
        data: {
          simulasiconfig_id: self.dataForm.simulasiId.simulasiconfig_id,
          is_combo: self.is_combo,
        },
      };
      axios(config).then(() => {

        let timerInterval;
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Anda akan diarahkan ke halaman dashboard segera",
          timer: 1500,
          timerProgressBar: true,
          showCancelButton: false,
          showConfirmButton: false,
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then(() => {
          this.$emit("closeModal");
        });
      });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <b-form-group
        class="mb-3"
        label="Duplikate Pertanyaan Simulasi"
        label-for="formrow-duplikat-pertanyaan"
      >
        <multiselect
          v-model="dataForm.simulasiId"
          :options="listSimulasi"
          :object="true"
          value-prop="simulasiconfig_id"
          :close-on-select="true"
          track-by="nama"
          :searchable="true"
          label="nama"
          placeholder="Pilih Simulasi"
          @search-change="searchSimulasiConfig"
        >
          <template #option="{ option }">
            <div>{{ option.nama }} - {{ option.sub_nama }}</div>
          </template>
        </multiselect>
      </b-form-group>
    </div>
    <div class="col-12 d-flex justify-content-end">
      <button
        class="btn btn-secondary"
        style="margin-right: 10px"
        @click="$emit('closeModal')"
      ><i class="fa fa-times"></i>
        Cancel</button>
      <button
        class="btn btn-success"
        @click="saveDuplicate()"
        :disabled="dataForm.simulasiId == undefined"
      ><i class="fa fa-save"></i> Save</button>
    </div>
  </div>
</template>
