<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import nestedDraggable from "@/router/layouts/nested-drag";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Ordering Menu",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    nestedDraggable,
  },
  data() {
    return {
      title: "Ordering Menu",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Menu",
          href: "/master/menu",
        },
        {
          text: "Ordering Menu",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      list_menu: [],

      // example list nested
      listNested: [
        {
          id: 1,
          icon: "",
          name: "Menu",
          sub: [],
        },
        {
          id: 2,
          name: "Dashboard",
          icon: "bx bx-home-circle",
          sub: [],
        },
        {
          id: 3,
          icon: "",
          name: "Master",
          sub: [],
        },
        {
          id: 4,
          name: "Role",
          icon: "bx bx-user-pin",
          sub: [],
        },
        {
          id: 5,
          name: "Menu",
          icon: "bx bx-list-ol",
          sub: [],
        },
        {
          id: 6,
          name: "User",
          icon: "bx bx-user",
          sub: [],
        },
        {
          id: 7,
          name: "Konfigurasi",
          icon: "bx bx-wrench",
          sub: [],
        },
      ],
    };
  },
  mounted() {
    let self = this;
    Swal.fire({
      title: '<i class="fas fa-spinner fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    // load data menu ordering
    var config_data_menu_ordering = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/menu/order",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_data_menu_ordering)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.list_menu = response_data_fix;
          Swal.close();
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function () {
      });
  },
  methods: {
    checkMoveNested: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    resetPage() {
      location.reload();
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/menu/order",
        data: {
          list_menu: JSON.stringify(self.list_menu),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function () {

          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Halaman akan di muat ulang",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              // self.$router.push({ name: "all-menu" });
              location.reload();
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form
            class="p-2"
            @submit.prevent="StoreData"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card-title">Silahkan Mengurutkan Menu</div>
                </div>
                <div class="col-lg-12">
                  <div v-if="axiosCatchError">
                    <div
                      class="alert alert-danger"
                      v-if="
                        typeof axiosCatchError === 'string' ||
                        axiosCatchError instanceof String
                      "
                    >
                      {{ axiosCatchError }}
                    </div>
                    <div
                      class="alert alert-danger"
                      role="alert"
                      v-else
                    >
                      <div
                        v-for="(errorArray, idx) in axiosCatchError"
                        :key="idx"
                      >
                        <div
                          v-for="(allErrors, idx) in errorArray"
                          :key="idx"
                        >
                          <span class="text-danger">{{ allErrors[0] }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="p-2">
                    <nested-draggable
                      :tasks="list_menu"
                      :move="checkMoveNested"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <div class="text-start">
                    <router-link
                      :to="{ name: 'all-menu' }"
                      class="btn btn-warning"
                    ><i class="fa fa-arrow-left"></i> Kembali</router-link>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    <b-button
                      type="button"
                      variant="danger"
                      v-on:click="resetPage()"
                    ><i class="fa fa-redo-alt"></i> Reset</b-button>
                    &nbsp;
                    <b-button
                      type="submit"
                      variant="primary"
                    ><i class="fa fa-save"></i> Simpan</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
