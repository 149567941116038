<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Page",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah Page",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Page",
          href: "/master/page",
        },
        {
          text: "Tambah Page",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variable Page
      nama_page: null,
      deskripsi_page: null,
      rows: [],

      // variable add page
      isOpen: false,
      tempRow: 0,
      tempColumn: 0,
      isSave: false,
      isEdit: false,
      formColumn: {
        grid: '',
        component: '',
        order: ''
      }
    };
  },
  mounted() { },
  methods: {
    // Method dari masing masing fungsi di dalam file ini
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      let postData = {
        nama_page: self.nama_page,
        deskripsi_page: self.deskripsi_page,
        detail_page: self.rows
      };

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/page",
        data: postData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          'Content-Type': 'application/json'
        },
      };

      axios(config_store_data)
        .then(function () {

          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master page segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-page" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },

    addRow() {
      let self = this;
      let rowNo = self.rows.length + 1;
      let newRow = {
        number: rowNo,
        columns: []
      };
      self.rows.push(newRow);
    },
    deleteRow(rowNumber) {
      let self = this;
      let counter = 1;
      self.rows = self.rows.filter(obj => obj.number != rowNumber);
      self.rows.map(obj => {
        obj.number = counter;
        counter++;
      });
    },
    showModalAddColumn(rowNumber) {
      let self = this;
      self.isOpen = true;
      self.resetFormColumn();
      self.tempRow = rowNumber;
      self.isSave = true;
      self.isEdit = false;
    },
    addColumn() {
      let self = this;
      let rowNumber = self.tempRow;
      let rowKey = self.rows.findIndex(value => value.number == rowNumber);
      let columnNo = self.rows[rowKey].columns.length + 1;
      self.rows[rowKey].columns.push({
        number: columnNo,
        grid: self.formColumn.grid,
        component: self.formColumn.component,
        order: self.formColumn.order
      });
      //order column by column.order
      self.rows[rowKey].columns.sort((a, b) => a.order - b.order);
      self.resetFormColumn();
      self.isOpen = false;
    },
    showModalEditColumn(rowNumber, columnNumber) {
      let self = this;
      let rowKey = self.rows.findIndex(value => value.number == rowNumber);
      let columnKey = self.rows[rowKey].columns.findIndex(value => value.number == columnNumber);
      self.formColumn = {
        number: columnKey,
        grid: self.rows[rowKey].columns[columnKey].grid,
        component: self.rows[rowKey].columns[columnKey].component,
        order: self.rows[rowKey].columns[columnKey].order
      }
      self.tempRow = rowNumber;
      self.tempColumn = columnNumber;

      self.isSave = false;
      self.isEdit = true;

      self.isOpen = true;
    },
    editColumn(rowNumber, columnNumber) {
      let self = this;
      let rowKey = self.rows.findIndex(value => value.number == rowNumber);
      let columnKey = self.rows[rowKey].columns.findIndex(value => value.number == columnNumber);
      self.rows[rowKey].columns[columnKey] = {
        number: columnKey,
        grid: self.formColumn.grid,
        component: self.formColumn.component,
        order: self.formColumn.order
      };
      self.rows[rowKey].columns.sort((a, b) => a.order - b.order);
      self.resetFormColumn();
      self.isOpen = false;
    },
    saveColumn() {
      let self = this;
      if (self.isSave) {
        self.addColumn();
      } else {
        self.editColumn(self.tempRow, self.tempColumn);
      }
    },
    deleteColumn(rowNumber, columnNumber) {
      let self = this;
      let rowKey = self.rows.findIndex(value => value.number == rowNumber);
      self.rows[rowKey].columns = self.rows[rowKey].columns.filter(obj => obj.number != columnNumber);
    },
    resetFormColumn() {
      let self = this;
      self.formColumn = {
        grid: '',
        component: '',
        order: ''
      };
    }
  },
};
</script>
<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form
            class="p-2"
            @submit.prevent="StoreData"
          >
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-else
                >
                  <div
                    v-for="(errorArray, idx) in axiosCatchError"
                    :key="idx"
                  >
                    <div
                      v-for="(allErrors, idx) in errorArray"
                      :key="idx"
                    >
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Nama Page"
                    label-for="formrow-nama-page-input"
                  >
                    <b-form-input
                      id="formrow-nama-page-input"
                      placeholder="Masukkan Nama Page..."
                      type="text"
                      v-model="nama_page"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Deskripsi Page"
                    label-for="formrow-deskripsi-page-input"
                  >
                    <b-form-input
                      id="formrow-deskripsi-page-input"
                      placeholder="Masukkan Deskripsi page..."
                      type="text"
                      v-model="deskripsi_page"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-6">
                  <div class="btn-toolbar">
                    <b-button
                      type="button"
                      variant="primary"
                      @click="addRow"
                    ><i class="fa fa-plus"></i> Add Row</b-button>
                  </div>
                </div>
              </div>
              <hr>
              <div
                v-for="(row, index) in rows"
                :key="index"
                class="row"
              >
                <div class="col-lg-12 col-md-12">
                  <div class="card shadow-none border">
                    <div class="card-body p-3">
                      <div class="">
                        <div class="float-end ms-2">
                          <b-dropdown
                            toggle-class="font-size-16 text-muted p-0"
                            menu-class="dropdown-menu-end"
                            class="mb-2"
                            variant="white"
                            right
                          >
                            <template #button-content>
                              <i class="mdi mdi-dots-horizontal"></i>
                            </template>
                            <b-dropdown-item
                              href="#"
                              @click="showModalAddColumn(`${row.number}`)"
                            >Add Column</b-dropdown-item>
                            <b-dropdown-item
                              href="#"
                              @click="deleteRow(`${row.number}`)"
                            >Delete Row</b-dropdown-item>
                          </b-dropdown>
                        </div>
                        <div class="avatar-xs me-3 mb-3">
                          <div class="avatar-title bg-transparent rounded">
                            <h5 class="font-size-14 text-truncate mb-1">
                              Row
                            </h5>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            v-for="(column, key) in row.columns"
                            :key="key"
                            :class="`col-lg-${column.grid} col-lg-${column.grid}`"
                          >
                            <div class="card shadow-none border">
                              <div class="card-body p-3">
                                <div class="float-end ms-2">
                                  <b-dropdown
                                    toggle-class="font-size-16 text-muted p-0"
                                    menu-class="dropdown-menu-end"
                                    class="mb-2"
                                    variant="white"
                                    right
                                  >
                                    <template #button-content>
                                      <i class="mdi mdi-dots-horizontal"></i>
                                    </template>
                                    <b-dropdown-item
                                      href="#"
                                      @click="showModalEditColumn(`${row.number}`, `${column.number}`)"
                                    >Edit
                                      Column</b-dropdown-item>
                                    <b-dropdown-item
                                      href="#"
                                      @click="deleteColumn(`${row.number}`, `${column.number}`)"
                                    >Delete
                                      Column</b-dropdown-item>
                                  </b-dropdown>
                                </div>
                                <h5 class="font-size-12 text-truncate mb-1">
                                  Col-{{ column.grid }}
                                </h5>
                                <h5 class="font-size-12 text-truncate mb-1">
                                  Component : {{ column.component }}
                                </h5>
                                <h5 class="font-size-12 text-truncate mb-1">
                                  Urutan : {{ column.order }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <div class="text-start">
                    <router-link
                      :to="{ name: 'all-page' }"
                      class="btn btn-warning"
                    ><i class="fa fa-arrow-left"></i>
                      Kembali</router-link>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    <b-button
                      type="reset"
                      variant="danger"
                    ><i class="fa fa-redo-alt"></i> Reset</b-button>
                    &nbsp;
                    <b-button
                      type="submit"
                      variant="primary"
                    ><i class="fa fa-save"></i> Simpan</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>

    <b-modal
      v-model="isOpen"
      title="Tambah Column"
      title-class="font-18"
    >
      <b-form-group
        class="mb-3"
        label="Grid"
        label-for="formrow-grid-input"
      >
        <b-form-select
          v-model="formColumn.grid"
          id="formrow-grid-input"
        >
          <b-form-select-option :value="''">Please select an option</b-form-select-option>
          <b-form-select-option
            v-for="n in 12"
            :key="n"
            :value="n"
          >Col-{{ n }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        class="mb-3"
        label="Nama File Component (tanpa ekstensi .vue)"
        label-for="formrow-component-input"
      >
        <b-form-input
          id="formrow-component-input"
          placeholder="Masukkan Nama File Component"
          type="text"
          v-model="formColumn.component"
        />

      </b-form-group>
      <b-form-group
        class="mb-3"
        label="Urutan"
        label-for="formrow-urutan-input"
      >
        <b-form-input
          id="formrow-urutan-input"
          placeholder="Masukkan Urutan"
          type="number"
          min="1"
          v-model="formColumn.order"
        />
        <input
          type="hidden"
          v-model="tempRow"
        />
        <input
          type="hidden"
          v-model="tempColumn"
        />

      </b-form-group>
      <template #footer>
        <b-button @click="isOpen = false">
          <i class="fa fa-times"></i> Cancel
        </b-button>
        <b-button
          variant="success"
          @click="saveColumn()"
        >
          <i class="fa fa-save"></i> Save
        </b-button>
      </template>
    </b-modal>

  </Layout>
</template>
