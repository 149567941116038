<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      token: this.$route.query.token,
      status: this.$route.query.status,
      menu: "",
      username: "",
      password: "",
      is_one_time: false,
      configuration: [],
      source: "",
    };
  },
  mounted() {
    localStorage.removeItem("user");
    localStorage.removeItem("session");
    localStorage.removeItem("session_username");
    localStorage.removeItem("session_name");
    localStorage.removeItem("session_path_photo");
    localStorage.removeItem("access_token");
    localStorage.removeItem("session_role");
    localStorage.removeItem("session_role_active_id");
    localStorage.removeItem("session_role_active_name");
    localStorage.removeItem("session_role_active_index");
    localStorage.removeItem("session_menu_access");
    localStorage.removeItem("reload_already");
    localStorage.removeItem("dateLogin");
    localStorage.removeItem("status");
    localStorage.removeItem("menu");
    this.configLogin();
  },
  methods: {
    async prosesJumper() {
      let self = this;
      let status_fix = "";
      if (self.status == undefined) {
        status_fix = "all";
      } else {
        status_fix = self.status;
      }
      var config_axios = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/login-portal",
        data: {
          token: this.token,
          status: status_fix,
        },
      };
      axios(config_axios)
        .then((response) => {
          if (response.data.meta.code == 200) {
            this.username = response.data.data.username;
            this.password = this.username;
            this.prosesLogin(this.username);
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Token anda tidak valid, Silahkan login ulang",
          }).then(function () {
            let a = document.createElement("a");
            a.href = process.env.VUE_APP_URL;
            a.click();
          });
        });
    },
    prosesLogin(username) {
      let self = this;
      axios
        .post(process.env.VUE_APP_BACKEND_URL_VERSION + "auth/login", {
          username: username,
          password: username,
          is_one_time: this.is_one_time,
        })
        .then((response) => {
          var data_login = response.data.data;
          // check status login

          if (data_login.status_login == false) {
            Swal.fire({
              icon: "warning",
              title: "Oopss",
              text: data_login.message_login,
              showCancelButton: false,
              showConfirmButton: true,
            }).then(function () {
              let a = document.createElement("a");
              a.href = process.env.VUE_APP_URL;
              a.click();
            });
          } else {
            // inisialisasi variabel localstorage untuk session
            localStorage.setItem("session_user_id", data_login.token.user.user_id); // session user id

            localStorage.setItem("session_username", data_login.token.user.username); // session username

            localStorage.setItem("session_name", data_login.token.user.name); // session name

            localStorage.setItem("session_path_photo", data_login.token.user.path_photo); // session path photo

            localStorage.setItem("access_token", data_login.token.access_token); // akses token

            localStorage.setItem("session_role", JSON.stringify(data_login.token.role)); // session role

            localStorage.setItem("session_role_active_id", data_login.token.role[0].role.id_role); // session role active id

            localStorage.setItem("session_role_active_name", data_login.token.role[0].role.role_name); // session role active name

            localStorage.setItem("session_role_active_index", 0); // session role active index

            localStorage.setItem("session_menu_access", JSON.stringify(data_login.token.menu_access[0].role_menu)); // set session meu access user

            localStorage.setItem("session", JSON.stringify(data_login.token)); // set all response to session

            localStorage.setItem("user", JSON.stringify(data_login.token.user)); // set session user
            localStorage.setItem("configuration", JSON.stringify(self.configuration)); // set session configuration

            localStorage.setItem("reload_already", "no");
            localStorage.setItem("dateLogin", Date.now());

            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman dashboard segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */

              // return false;
              if (localStorage.session && localStorage.session_name && localStorage.access_token) {
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.$router.push({ name: "dashboard" });
                }
              }
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Token anda tidak valid, Silahkan login ulang",
          }).then(function () {
            let a = document.createElement("a");
            a.href = process.env.VUE_APP_URL;
            a.click();
          });
        });
    },
    configLogin() {
      let self = this;
      var config_login = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/konfig-login",
        headers: {
          Accept: "application/json",
        },
      };
      axios(config_login)
        .then(function (response) {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.configuration = response_data_fix.referensi;
            self.prosesJumper();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(function () {
        });
    },
  },
};
</script>
<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h2 class="text-uppercase">Loading...</h2>

            <div class="mt-5 text-center"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
